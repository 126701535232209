import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { FormControlState, ProvideFormControl } from "@/components/FormControlContext/FormControlContext";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import { computed } from "@/util/functions";
import "./faItemRoot.scss";

type FaItemOwnRootProps = {
    disabled?: boolean;
    state?: FormControlState;

    /**
     * If set overrides the `state` property
     */
    error?: SingleValidationError;
};
export type FaItemRootProps = FaItemOwnRootProps & Omit<ComponentPropsWithoutRef<"div">, keyof FaItemOwnRootProps>;

export function FaItemRoot(props: FaItemRootProps) {
    const { disabled, state, error, onClick, ...restProps } = props;

    const computedState = computed((): FormControlState | undefined => {
        if (error) {
            return "error";
        }

        return state;
    });

    return (
        <ProvideFormControl disabled={disabled} state={computedState}>
            <div
                {...restProps}
                className={clsx(
                    "fa-item-root",
                    {
                        "fa-item-root--disabled": disabled,
                        "fa-item-root--clickable": onClick !== undefined,
                    },
                    restProps.className
                )}
                onClick={e => !disabled && onClick?.(e)}
            />
        </ProvideFormControl>
    );
}
