import "./faItemDivider.scss";

type FaItemDividerProps = {
    title: string;
};

export function FaItemDivider(props: FaItemDividerProps) {
    return (
        <div className="fa-item-divider">
            <div className="fa-item-divider__pre-title" />
            <div className="fa-item-divider__title">{props.title}</div>
            <div className="fa-item-divider__post-title" />
        </div>
    );
}
