import { createContext, useContext } from "react";
import { FormControlState } from "./FormControlContext";

type FormControlContext = {
    /**
     * ID of the input. Should be used to connect a <label> to a control like <input>, <select> etc
     */
    id: string;
    disabled: boolean;
    state: FormControlState;
};

export const FormControlContext = createContext<FormControlContext>({
    id: "",
    disabled: false,
    state: "normal",
});

export function useFormControlContext() {
    return useContext(FormControlContext);
}
