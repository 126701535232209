import { Slot } from "@radix-ui/react-slot";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import "./faItemContent.scss";

type FaItemContentProps = ComponentPropsWithoutRef<typeof Slot>;

export function FaItemContent(props: FaItemContentProps) {
    const { disabled } = useFormControlContext();

    return (
        <Slot
            {...props}
            className={clsx(
                "fa-item-content",
                {
                    "fa-item-content--disabled": disabled,
                },
                props.className
            )}
        />
    );
}
