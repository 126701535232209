import { faInfoCircle, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import { Fragment, ReactNode, useState } from "react";
import { FormControlState } from "@/components/FormControlContext/FormControlContext";
import { useFormControlContext } from "@/components/FormControlContext/useFormControlContext";
import { computed } from "@/util/functions";
import "./faItemLabel.scss";

export type FaItemLabelProps = {
    align?: "start" | "end";

    tooltip?: ReactNode;
    warningTooltip?: ReactNode;
    errorTooltip?: ReactNode;
    customTooltips?: CustomItemLabelTooltip | CustomItemLabelTooltip[];

    children?: ReactNode;
};

export function FaItemLabel(props: FaItemLabelProps) {
    const { customTooltips = [], ...restProps } = props;
    const customTooltipsArray = Array.isArray(customTooltips) ? customTooltips : [customTooltips];

    const { id, state, disabled } = useFormControlContext();

    const hasTooltips = [restProps.tooltip, restProps.warningTooltip, restProps.errorTooltip].some(Boolean);
    const hasCustomTooltips = customTooltipsArray.length > 0;

    const renderedCustomTooltips = computed((): ReactNode => {
        if (customTooltipsArray.length === 0) {
            return null;
        }

        return customTooltipsArray.map((tooltip, index) => {
            return (
                <Fragment key={index}>
                    {createTooltip({
                        icon: tooltip.icon,
                    })({ children: tooltip.children })}
                </Fragment>
            );
        });
    });

    return (
        <Tooltip.TooltipProvider delayDuration={300}>
            <div
                className={clsx("fa-item-label", {
                    "fa-item-label--align-start": restProps.align === "start",
                    "fa-item-label--align-end": restProps.align === "end",
                    //
                    "fa-item-label--state-normal": state === "normal",
                    "fa-item-label--state-warning": state === "warning",
                    "fa-item-label--state-error": state === "error",
                })}>
                <label
                    htmlFor={id}
                    className={clsx("fa-item-label__label", {
                        "fa-item-label__label--disabled": disabled,
                    })}>
                    {restProps.children}
                </label>

                {(hasTooltips || hasCustomTooltips) && (
                    <div className="fa-item-label__tooltips">
                        {restProps.errorTooltip && <ErrorTooltip>{restProps.errorTooltip}</ErrorTooltip>}
                        {restProps.warningTooltip && <WarningTooltip>{restProps.warningTooltip}</WarningTooltip>}
                        {restProps.tooltip && <InfoTooltip>{restProps.tooltip}</InfoTooltip>}
                        {renderedCustomTooltips}
                    </div>
                )}
            </div>
        </Tooltip.TooltipProvider>
    );
}

type CreateTooltipParams = {
    icon: ReactNode;
    className?: string;
    when?: FormControlState;
    testId?: string;
};
type TooltipProps = {
    children: ReactNode;
};
function createTooltip(params: CreateTooltipParams) {
    const { icon, className, when, testId } = params;

    return (props: TooltipProps) => {
        const { children } = props;

        const { state } = useFormControlContext();

        const [open, setOpen] = useState(false);

        if (when !== undefined && when !== state) {
            return null;
        }

        return (
            <Tooltip.Root open={open} onOpenChange={setOpen}>
                <Tooltip.Trigger asChild data-testid={testId}>
                    <span className={className} onClick={() => setOpen(true)}>
                        {icon}
                    </span>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content className="fa-item-label__tooltip" collisionPadding={8}>
                        {children}
                        <Tooltip.Arrow className="fa-item-label__tooltip-arrow" />
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip.Root>
        );
    };
}

const InfoTooltip = createTooltip({
    icon: <FontAwesomeIcon icon={faInfoCircle} />,
    className: "fa-item-label__info-tooltip",
    testId: "fa-item-label-info-tooltip",
});
const WarningTooltip = createTooltip({
    icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
    className: "fa-item-label__warning-tooltip",
    when: "warning",
    testId: "fa-item-label-warning-tooltip",
});
const ErrorTooltip = createTooltip({
    icon: <FontAwesomeIcon icon={faTriangleExclamation} />,
    className: "fa-item-label__error-tooltip",
    when: "error",
    testId: "fa-item-label-error-tooltip",
});

export type CustomItemLabelTooltip = {
    icon: ReactNode;
    children: ReactNode;
};
