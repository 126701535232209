import { ReactNode, useId } from "react";
import { FormControlContext } from "./useFormControlContext";
export type FormControlState = "normal" | "warning" | "error";

type ProvideFormControlProps = {
    id?: string;
    disabled?: boolean;
    state?: FormControlState;
    children: ReactNode;
};
export function ProvideFormControl(props: ProvideFormControlProps) {
    const uncontrolledId = useId();

    return (
        <FormControlContext.Provider
            value={{
                id: props.id ?? uncontrolledId,
                disabled: props.disabled ?? false,
                state: props.state ?? "normal",
            }}>
            {props.children}
        </FormControlContext.Provider>
    );
}
